
  .sidebarsearchdetails_modal::placeholder{
    color: rgb(143, 143, 143) !important;
    margin-left: 10px;
  }
  
  .search-results {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
  }
  
  .search-item {
    padding: 10px;
    cursor: pointer;
    
  }
  
  /* .search-item:hover {
    background-color: #e6f7ff;
  }
   */
  .search-titleoptimised {
    font-weight: bold;
    color: #5e5e5e;
  }
  
  .search-path {
    display: block;
    font-size: 12px;
    color: #326791;
  }
  .facroommodal .modal-content{
    height: 450px;
    overflow-y: auto;
    width: 740px !important;    
    
  }
  .highlighted {
    background-color: rgb(255, 255, 180) !important;
    font-weight: bold;
    color: black;
}
  

.highlight {
  background-color: #fa707b;
  font-weight: 900; 
 color:white;
  border-radius: 3px;
  padding: 2px;
  font-size: 15px;
}

  