.facility_container {
    padding: 20px;
  }
  
  .facility-section {
    margin-bottom: 30px;
  }
  
  .facility-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    text-transform: none;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
  }
  
  .facility-card {
    display:flex;
    gap:6px
    
  }

  .facility-image {
    width: 18px;
    height: 18px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 12px;
  
  }
  
  .facility-name {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .facroommodal{
    background-color: rgba(0, 0, 0, 0.2) !important; /* Reduce opacity */
  }