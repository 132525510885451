.loading-button {
    position: relative;
    display: inline-block;
    cursor: not-allowed;
    overflow: hidden;
  }
  .paymentelementstrucuture{
    width: 600px;
    margin: auto;
    height: 135vh;
    padding: 10px;
    background: white;
    border-radius: 15px;
  }.paymentelementstrucutureoffline{
    width: 600px;
    margin: auto;
    height: 100%;
    padding-bottom: 25px;
    margin-top: 35px;

    background: white;
    border-radius: 15px;
  }
.paymentelementstrucuture form{
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07) !important;
  border-radius: 7px;
  padding: 40px;
}
.paymentelementstrucutureoffline form{
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07) !important;
  border-radius: 7px;
  padding: 40px;
}
.spinnersbuttoncss{
  text-align: center !important;
}
  .loading-button::after {
    content: '...';
    font-size:20px;
    position: absolute;
    top: 50%;
    left: 69%;
    transform: translate(-50%, -50%);
    animation: loading 1s infinite;
  }

  @keyframes loading {
    0% {    
        left: 67%;
      content: '.';
    }
    33% {
        left: 68%;
      content: '..';
    }
    66% {
      content: '...';
    }
  }

  
  .imgM {
    position: relative;
    width: 20px;
    margin-left: 12px;
    animation: sendM 2s infinite linear;
}

.imgM::after {
    content: '';
    position: absolute;
    width: 20px;
   
    height: 20px;
    /* background: url('/hotel-react-ui/src/assets/images/sendMsg.png') no-repeat; */
    background-size: cover; /* adjust to your needs */
    top: 0;
    left: 0;
    animation: flyM 2s infinite linear;
}

@keyframes sendM {
    0% {
        transform: translateX(0) translateY(0) rotate(-20deg);
    }
    50% {
        transform: translateX(100%) translateY(-50%) rotate(-30deg);
    }
    100% {
        transform: translateX(200%) translateY(-100%) rotate(-35deg);
    }
}

@keyframes flyM {
    0% {
        transform: translateX(0) translateY(0) rotate(0deg);
    }
    50% {
        transform: translateX(100%) translateY(-50%) rotate(-30deg);
    }
    100% {
        transform: translateX(200%) translateY(-100%) rotate(-35deg);
    }
}


@keyframes prixClipFix {
  0% { width: 0; }
  50% { width: 50%; }
  100% { width: 100%; }
}

.loader-wrapper {
  position: relative;
  display: inline-block;
}

.loader {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px; /* Border thickness */
  background: red;
}

.circle{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: #ccc;
  border: 3px solid #a9a9a9;
  border-radius: 50%;
  overflow: hidden;
}
.wave{
  position: relative;
  width: 100%;
  height: 100%;
  background: #4973ff;
  border-radius: 50%;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
}
.wave:before,
.wave:after
{
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  margin-top:-50px;
  left: 50%;
  transform: translate(-50%, -75%);
}
.wave:before{
  border-radius: 45%;
  background: rgba(255, 255, 255, 1);
  animation: animate  5s linear infinite;
}
.wave:after{
  border-radius: 40%;
  background: rgba(255, 255, 255, .2);
  animation: animate  5s linear infinite;
}
@keyframes animate{
  0%{
  margin-top:-10px;
      transform: translate(-50%, -75%) rotate(0deg);
  }
  25%{
    margin-top:-20px;
      transform: translate(-50%, -75%) rotate(90deg);
  }
   50%{
    margin-top:-30px;
      transform: translate(-50%, -75%) rotate(180deg);
  }
  75%{
    margin-top:-40px;
      transform: translate(-50%, -75%) rotate(270deg);
  }
  100%{
    margin-top:-50px;
      transform: translate(-50%, -75%) rotate(360deg);
  }
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveDollar {
  0% {
    left: 10;
    visibility: visible;
  }
  10% {
    left: 0;
    transform: rotate(0deg);
  }

  95% {
    left: 100px; /* Adjust the distance to move */
    transform: rotate(360deg);
  }
  100% {
    left: 100px; /* Keep it at the final position */
    visibility: hidden;
    display: none;
  }
}

.rotating-image {
  position: relative; /* Changed from absolute to relative */
  margin-top: 20px;
  width: 25px; /* Adjust the width and height as needed */
  height: 25px;
  margin-left: -120px;
  animation: moveDollar 3s linear infinite; /* Change the duration and timing function as needed */
}

.container {
  position: relative;
}




.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}



.checkmark {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  position:relative;
  top: 5px;
  right: 5px;
 margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
      stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
      transform: none;
  }

  50% {
      transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
      box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}


