.head {
  border-collapse: collapse !important;
  border-radius: 20px !important;
  vertical-align: middle;
}

.unset{
  min-width: 132px !important;
}


.transplant-button {
  box-sizing: border-box;
  width: 120px;
  height: 32px;
  left: 1061px;
  top: 950px;
  background: transparent;
  border: 0.7px solid #47ADFF;
  border-radius: 12px;
  color: #47ADFF;
}

.transplant-button:disabled {
  background-color: #e0e0e0; /* Light gray background */
  color: #9e9e9e;           /* Gray text */
  cursor: not-allowed;      /* Shows a not-allowed cursor */
  border: 1px solid #d6d6d6; /* Optional: Border color */
  opacity: 0.6;             /* Optional: Slightly faded look */
}

.transplant-button:hover {
  animation-name: facility_upload;
  animation-duration: 4s;
  background-color: #47ADFF;
  color: white;
}

@keyframes facility_upload {
  from {
    background-color: transparent;
    color: #47ADFF;
  }
  to {
    background-color: #47ADFF;
    color: white;
  }
}

.dark-blue {
  width: 5px;
  height: 7px;
  left: 1080px;
  top: 970px;
  color: #7BB6FF;
  transform: rotate(-180deg);
}

.light-blue {
  width: 5px;
  height: 7px;
  left: 1086px;
  top: 970px;
  color: rgba(71, 173, 255, 0.5);
  transform: rotate(-180deg);
}

.title {
  font-size: 17px;
  margin-left: 5px;
  margin-right: 5px;
}

.number {
  color: white;
}

.custom-pagination li a {
  padding-left: 10px;
  padding-right: 10px;
}

.number a {
  border-radius: 20px !important;
  background-color: #47ADFF !important;
}

.custom-pagination {  
  display: flex;
  list-style-type: none;
  align-items: center;
  overflow-x: auto;
}

.custom-pagination > ul {
  display: flex;
  justify-content: right;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-pagination ul {
  background: white;
  background-color: white;
  margin-left: 5px;
  margin-right: 5px;
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
  white-space: nowrap; /* Prevent line wrapping */
}

.custom-pagination li {
  padding: 5px;
  background-color: white;
}

.custom-pagination li a {
  margin-left: 7px;
  margin-right: 7px;
}

.custom-pagination li:nth-child(2) {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.custom-pagination li:nth-last-child(2) {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.custom-pagination li:first-child,
.custom-pagination li:last-child {
  margin-left: 17px;
  margin-right: 17px;
  background: transparent;
}

.custom-pagination li a:first-child:hover {
  margin-left: 2px;
}

.page-num {
  background-color: white;
}

.primary {
  background: #A6D4FF;
  color: #0747A6;
  border: 1px solid #0747A6;
}

.danger {
  background: none;
  color: #D32F2F;
  border: 1px solid #D32F2F;
}

.print {
  background: #FFFFFF !important;
  border-radius: 5px;
  color: #5F5F5F !important;
  font-weight: 600;
  border: 0;
}

.asc_desc {
  cursor: pointer;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
  .transplant-button {
    width: 100px;
    height: 28px;
  }

  .dark-blue,
  .light-blue {
    display: none;
  }

  .title {
    font-size: 15px;
  }

  .custom-pagination li a {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 480px) {
  .transplant-button {
    width: 90px;
    height: 26px;
  }

  .title {
    font-size: 14px;
  }

  .custom-pagination li a {
    padding-left: 6px;
    padding-right: 6px;
  }
}



/* iPad Mini */
@media screen and (max-width: 768px) and (max-height: 1024px) {
  .transplant-button {
    width: 90px;
    height: 26px;
  }

  .title {
    font-size: 14px;
  }

  .custom-pagination li a {
    padding-left: 6px;
    padding-right: 6px;
  }
}


/* Galaxy Fold */
@media screen and (max-width: 280px) and (max-height: 653px) {
  .transplant-button {
    width: auto;
    height: auto;
    font-size: 10px;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1;
    margin-left: -5px; /* Add left margin */

  }

  .title {
    font-size: 10px;
  }

  .custom-pagination li a {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.warningContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningSpan{
  max-width: 100%;
      height: 65px;
      /* margin-left: 20px; */
      background: #FFFCF2;
      border: 1px solid #7F691D;
      border-radius: 5px;
      margin-bottom: 20px;
      margin: auto;
}

.warnImg{
  width: 25px;
  height: 25px;
  margin-top: -10px;
  margin-right: 10px;
}

.warnP{
  color: #7F691D;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
}

@media only screen and (max-width: 768px) {
  .warningContainer{
   margin-top: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
  }

}