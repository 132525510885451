/* Dashboard card 1 */

.dashboardcard1 {
  position: absolute;
  width: 258px;
  height: 106px;
  left: 281px;
  top: 120px;
  background: #FCFCFC;
  border-radius: 12px;
}
.skeletonbackgroudloader{
  position: relative;
  width: 100%;
  height: 110px;
}
.skeletonbackgroudloaderactivity{
  position: relative;
  width: 100%;
  height: 56px;
}
.skeletoncards{
  cursor: pointer;
  width: 100%;
  height: 110px;
}
#departuresCard , #arrivalsCard,#stayoversCard,#occupancyCard{
  height: 110px !important;
}
.shepherd-footer{
  justify-content: center !important;
}
.shepherd-button{
  background: rgba(69, 90, 100, 1) !important;
  border-radius: 10px !important;
  width: 100px !important;


} 
.apexcharts-legend{
  margin-top: -30px;
}
.badge-overlay-newoverlay{
  position: absolute;
  left: 0%;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
}
.badge-newoverlay {
  margin: 0;
  padding: 0;
  color: white;
  padding: 4px 4px;
  font-size: 10px;
  font-family: "Inter";
  text-align: center;
  line-height: normal;
  text-transform: none;
  background:#CC0000;
  border:none
}

.badge-newoverlay::before, .badge-newoverlay::after {
  content: "";
  position: absolute;
  top: 0;
 
  width: 100%;
  height: 100%;
  background: inherit;
 
}

.badge-newoverlay::before {
  right: 100%;
}

.badge-newoverlay::after {
  left: 100%;
}
.top-right-newoverlay {
  position: absolute;
  top: 0;
  right: 0;
  -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
  -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
  transform: translateX(30%) translateY(0%) rotate(45deg);
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}
@media screen and (max-width:825px) {
  #selectStatusBtn{

   width: 118px !important;
  }
 } 
 @media screen and (max-width:900px) {

  .reservationpagecheckoutbutton{
    width: 85px !important;
  }
 } 


.dashboardcard11 {
  position: absolute;
  width: 5px;
  height: 75px;
  left: 281px;
  top: 137px;

  background: #29973B;
  border-radius: 0px 20px 20px 0px;
}

.dashboardcardtext1 {
  position: absolute;
  width: 60px;
  height: 19px;
  left: 303px;
  top: 136px;

  font-family: "Inter, sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.dashboardcardtext11 {

  position: absolute;
  width: 46px;
  height: 29px;
  left: 306px;
  top: 161px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #29973B;
}

.dashboardcardicon1 {
  position: absolute;
  width: 46.08px;
  height: 45px;
  left: 466.34px;
  top: 151px;
}


/* Dashboard Card 2 */

.dashboardcard2 {
  position: absolute;
  width: 258px;
  height: 106px;
  left: 563px;
  top: 120px;

  /* BG 3 */

  background: #FCFCFC;
  border-radius: 12px;
}
.room-cell {
  white-space: nowrap; /* Prevent line breaks by default */
}

@media screen and (max-width: 900px) {
  .room-cell {
    white-space: pre-line; /* Allow line breaks for smaller screens */
  }
}

.dashboardcard22 {

  position: absolute;
  width: 5px;
  height: 75px;
  left: 563px;
  top: 136px;

  background: #D32F2F;
  border-radius: 0px 20px 20px 0px;
}

.dashboardcardtext2 {
  position: absolute;
  width: 86px;
  height: 19px;
  left: 585px;
  top: 136px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.dashboardcardtext22 {

  position: absolute;
  width: 46px;
  height: 29px;
  left: 588px;
  top: 160px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */


  color: #D32F2F;

}

.dashboardcardicon2 {
  position: absolute;
  width: 46.08px;
  height: 45px;
  left: 748.34px;
  top: 151px;

}

/* Dashboard Card 3 */

.dashboardcard3 {
  position: absolute;
  width: 258px;
  height: 106px;
  left: 845px;
  top: 120px;

  /* BG 3 */

  background: #FCFCFC;
  border-radius: 12px;
}

.dashboardcard33 {
  position: absolute;
  width: 5px;
  height: 75px;
  left: 845px;
  top: 136px;

  background: #4E98F3;
  border-radius: 0px 20px 20px 0px;

}

.dashboardcardtext3 {
  position: absolute;
  width: 100px;
  height: 19px;
  left: 867px;
  top: 136px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.dashboardcardtext33 {
  position: absolute;
  width: 53px;
  height: 29px;
  left: 870px;
  top: 160px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */


  color: #0747A6;

}

.dashboardcardicon3 {
  position: absolute;
  width: 46.08px;
  height: 45px;
  left: 1030px;
  top: 151px;
}


/* Dashboard Card 4 */

.dashboardcard4 {
  position: absolute;
  width: 258px;
  height: 106px;
  left: 1127px;
  top: 121px;
  background: #FCFCFC;
  border-radius: 12px;
}

.dashboardcard44 {
  position: absolute;
  width: 5px;
  height: 75px;
  left: 1127px;
  top: 136px;
  background: #FF8A00;
  border-radius: 0px 20px 20px 0px;

}

.dashboardcardtext4 {
  position: absolute;
  width: 120px;
  height: 19px;
  left: 1149px;
  top: 136px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0E0E0E;
}

.dashboardcardtext44 {
  position: absolute;
  width: 68px;
  height: 29px;
  left: 1152px;
  top: 160px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #FF8A00;
}

.dashboardcardicon4 {
  position: absolute;
  width: 41.05px;
  height: 40.09px;
  left: 1314.86px;
  top: 153.45px;
  background: #FFEAD2;
}

/* dashboard reservation table */
.reservation1 {
  position: absolute;
  width: 540px;
  height: 567px;
  left: 281px;
  top: 275px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.reservation11 {
  position: absolute;
  width: 540px;
  height: 56px;
  left: 281px;
  top: 275px;

  background: linear-gradient(270deg, #47ADFF 0%, #52A1FF 101.48%);
  border-radius: 10px 10px 0px 0px;
}

.reservationtext1 {
  position: absolute;
  width: 149px;
  height: 24px;
  left: 292px;
  top: 291px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FEFEFE;

}

.reservationbutton {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 779px;
  top: 291px;
}

.reservationheader {
  position: absolute;
  width: 508px;
  height: 40px;
  left: 297px;
  top: 355px;

  background: #EDEFF5;
}


.firsttext {

  position: absolute;
  width: 50px;
  height: 17px;
  left: 308px;
  top: 367px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #0E0E0E;
}


.secondtext {
  position: absolute;
  width: 74px;
  height: 17px;
  left: 392px;
  top: 366px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #0E0E0E;
}



.thirdtext {
  position: absolute;
  width: 90px;
  height: 17px;
  left: 490px;
  top: 366px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #0E0E0E;
}

.today {
  position: absolute;
  width: 41px;
  height: 17px;
  left: 304px;
  top: 428px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}


.tomorrow {
  position: absolute;
  width: 67px;
  height: 17px;
  left: 369px;
  top: 428px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.guestname {
  position: absolute;
  width: 90px;
  height: 15px;
  left: 658px;
  top: 427px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */


  color: #5F5F5F;
}

.downline {
  height: 55px;
  width: 3px;
  left: 352px;
  top: 456px;
  border-radius: 0px;

}


.searchline {
  position: absolute;
  width: 147px;
  height: 0px;
  left: 658px;
  top: 445px;

  border: 1px solid #5F5F5F;
}

.searchicon {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 786px;
  top: 430px;
}

.tableheader {
  position: absolute;
  width: 508px;
  height: 40px;
  left: 297px;
  top: 473px;
  background: #EDEFF5;
}


.bookingid {
  /* Booking ID */


  position: absolute;
  width: 100px;
  height: 19px;
  left: 313px;
  top: 485px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.guest {
  /* Guest */


  position: absolute;
  width: 46px;
  height: 19px;
  left: 460px;
  top: 485px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.room {
  /* Room */


  position: absolute;
  width: 44px;
  height: 19px;
  left: 570px;
  top: 485px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.status {
  /* Status */


  position: absolute;
  width: 50px;
  height: 19px;
  left: 678px;
  top: 485px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}



.tabletext1 {
  position: absolute;
  width: 35px;
  height: 17px;
  left: 337px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;


}

.tabletext11 {

  position: absolute;
  width: 50px;
  height: 17px;
  left: 460px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext111 {

  position: absolute;
  width: 43px;
  height: 17px;
  left: 570px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext1111 {
  position: absolute;
  width: 72px;
  height: 17px;
  left: 678px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #4271B2;
}

.box1 {

  position: absolute;
  width: 9px;
  height: 9px;
  left: 783px;
  top: 541px;

  background: #4271B2;
  border-radius: 2px;
}

.tabletext2 {
  position: absolute;
  width: 35px;
  height: 17px;
  left: 337px;
  top: 578px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;


}

.tabletext22 {

  position: absolute;
  width: 50px;
  height: 17px;
  left: 460px;
  top: 578px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext222 {

  position: absolute;
  width: 43px;
  height: 17px;
  left: 570px;
  top: 578px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext2222 {
  position: absolute;
  width: 72px;
  height: 17px;
  left: 678px;
  top: 578px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #4271B2;
}

.box2 {

  position: absolute;
  width: 9px;
  height: 9px;
  left: 783px;
  top: 582px;

  background: #4271B2;
  border-radius: 2px;
}



.tabletext3 {
  position: absolute;
  width: 35px;
  height: 17px;
  left: 337px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;


}

.tabletext33 {

  position: absolute;
  width: 50px;
  height: 17px;
  left: 460px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext333 {

  position: absolute;
  width: 43px;
  height: 17px;
  left: 570px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext3333 {
  position: absolute;
  width: 72px;
  height: 17px;
  left: 678px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #4271B2;
}

.box3 {

  position: absolute;
  width: 9px;
  height: 9px;
  left: 783px;
  top: 623px;

  background: #4271B2;
  border-radius: 2px;
}



.tabletext4 {
  position: absolute;
  width: 35px;
  height: 17px;
  left: 337px;
  top: 664px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;


}

.tabletext44 {

  position: absolute;
  width: 50px;
  height: 17px;
  left: 460px;
  top: 664px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext444 {

  position: absolute;
  width: 43px;
  height: 17px;
  left: 570px;
  top: 664px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext4444 {
  position: absolute;
  width: 72px;
  height: 17px;
  left: 678px;
  top: 664px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #4271B2;
}

.box4 {

  position: absolute;
  width: 9px;
  height: 9px;
  left: 783px;
  top: 664px;

  background: #4271B2;
  border-radius: 2px;
}


.tabletext5 {
  position: absolute;
  width: 35px;
  height: 17px;
  left: 337px;
  top: 705px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;


}

.tabletext55 {

  position: absolute;
  width: 50px;
  height: 17px;
  left: 460px;
  top: 705px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext555 {

  position: absolute;
  width: 43px;
  height: 17px;
  left: 570px;
  top: 705px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext5555 {
  position: absolute;
  width: 72px;
  height: 17px;
  left: 678px;
  top: 705px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #4271B2;
}

.box5 {
  position: absolute;
  width: 9px;
  height: 9px;
  left: 783px;
  top: 705px;

  background: #4271B2;
  border-radius: 2px;
}


.tabletext6 {
  position: absolute;
  width: 35px;
  height: 17px;
  left: 337px;
  top: 746px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;


}

.tabletext66 {

  position: absolute;
  width: 50px;
  height: 17px;
  left: 460px;
  top: 746px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext666 {

  position: absolute;
  width: 43px;
  height: 17px;
  left: 570px;
  top: 746px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #5F5F5F;
}

.tabletext6666 {
  position: absolute;
  width: 72px;
  height: 17px;
  left: 678px;
  top: 746px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #4271B2;
}

.box6 {
  position: absolute;
  width: 9px;
  height: 9px;
  left: 783px;
  top: 746px;

  background: #4271B2;
  border-radius: 2px;
}



.buttonstyle {
  position: absolute;
  width: 115px;
  height: 40px;
  left: 682px;
  top: 778px;

  background: #4E98F3;
  border-radius: 8px;
  border-color: #ffffff;
  color: #FFFFFF;
}



/* dashboard today's activity table */
.activity2 {
  position: absolute;
  width: 539px;
  height: 567px;
  left: 845px;
  top: 275px;
  background: #FFFFFF;
  border-radius: 10px;
}

.activity22 {
  position: absolute;
  width: 539px;
  height: 56px;
  left: 845px;
  top: 275px;

  background: linear-gradient(90deg, #52A1FF -1.39%, #47ADFF 101.67%);
  border-radius: 10px 10px 0px 0px;
}

.activitytext2 {
  position: absolute;
  width: 183px;
  height: 24px;
  left: 856px;
  top: 291px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FEFEFE;
}


.activitytabletext1 {
  position: absolute;
  width: 14px;
  height: 24px;
  left: 877px;
  top: 355px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #4E98F3;

}


.activitytabletext2 {
  position: absolute;
  width: 118px;
  height: 19px;
  left: 877px;
  top: 387px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}


.activitytabletext3 {
  position: absolute;
  width: 14px;
  height: 24px;
  left: 1033px;
  top: 355px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #4E98F3;
}


.activitytabletext4 {
  position: absolute;
  width: 94px;
  height: 19px;
  left: 1033px;
  top: 387px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.activitytabletext5 {
  position: absolute;
  width: 81px;
  height: 24px;
  left: 1169px;
  top: 355px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #4E98F3;
}

.activitytabletext6 {
  position: absolute;
  width: 66px;
  height: 19px;
  left: 1169px;
  top: 387px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.activityhead {
  position: absolute;
  width: 507px;
  height: 40px;
  left: 861px;
  top: 430px;

  background: #EDEFF5;
}

.activityheadtext01 {
  position: absolute;
  width: 46px;
  height: 19px;
  left: 877px;
  top: 442px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;

}


.activityheadtext02 {
  position: absolute;
  width: 90px;
  height: 19px;
  left: 987px;
  top: 442px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.activityheadtext03 {


  position: absolute;
  width: 59px;
  height: 19px;
  left: 1121px;
  top: 442px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}

.activityheadtext04 {

  position: absolute;
  width: 66px;
  height: 19px;
  left: 1244px;
  top: 442px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0E0E0E;
}


.activitytablecontent1 {
  position: absolute;
  width: 57px;
  height: 19px;
  left: 875px;
  top: 494px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent11 {
  position: absolute;
  width: 71px;
  height: 19px;
  left: 987px;
  top: 494px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}

.activitytablecontent111 {
  position: absolute;
  width: 64px;
  height: 19px;
  left: 1121px;
  top: 494px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent1111 {
  position: absolute;
  width: 40px;
  height: 19px;
  left: 1244px;
  top: 494px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}




.activitytablecontent2 {
  position: absolute;
  width: 57px;
  height: 19px;
  left: 875px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent22 {
  position: absolute;
  width: 71px;
  height: 19px;
  left: 987px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}

.activitytablecontent222 {
  position: absolute;
  width: 64px;
  height: 19px;
  left: 1121px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent2222 {
  position: absolute;
  width: 40px;
  height: 19px;
  left: 1244px;
  top: 537px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}




.activitytablecontent3 {
  position: absolute;
  width: 57px;
  height: 19px;
  left: 875px;
  top: 580px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent33 {
  position: absolute;
  width: 71px;
  height: 19px;
  left: 987px;
  top: 580px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}

.activitytablecontent333 {
  position: absolute;
  width: 64px;
  height: 19px;
  left: 1121px;
  top: 580px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent3333 {
  position: absolute;
  width: 40px;
  height: 19px;
  left: 1244px;
  top: 580px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}




.activitytablecontent4 {
  position: absolute;
  width: 57px;
  height: 19px;
  left: 875px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent44 {
  position: absolute;
  width: 71px;
  height: 19px;
  left: 987px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}

.activitytablecontent444 {
  position: absolute;
  width: 64px;
  height: 19px;
  left: 1121px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent4444 {
  position: absolute;
  width: 40px;
  height: 19px;
  left: 1244px;
  top: 623px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}



.activitytablecontent5 {
  position: absolute;
  width: 57px;
  height: 19px;
  left: 875px;
  top: 666px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent55 {
  position: absolute;
  width: 71px;
  height: 19px;
  left: 987px;
  top: 666px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}

.activitytablecontent555 {
  position: absolute;
  width: 64px;
  height: 19px;
  left: 1121px;
  top: 666px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;

}

.activitytablecontent5555 {
  position: absolute;
  width: 40px;
  height: 19px;
  left: 1244px;
  top: 666px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #5F5F5F;
}


.activitybuttonstyle {
  position: absolute;
  width: 115px;
  height: 40px;
  left: 1245px;
  top: 778px;

  background: #4E98F3;
  border-radius: 8px;
  border-color: #ffffff;
  color: #FFFFFF;
}

.solidline {
  position: absolute;
  width: 508px;
  height: 0px;
  left: 297px;
  top: 457px;
  border: 1px solid #EDEFF5;
}

.dash-responsive{
  max-zoom: 2;
}


@media screen and (max-width: 1100px) {
  .dash-responsive {
    max-width: 100%;
    padding: 10px;
    /* scale: 1.0; */
    
  }
  .dashboard-img{
    height: 130px;
  }
  /* .dashboard-p1{
    position: absolute;
   top: 17px;
   left: 0px !important;
   font-size: 8px !important;
  } */
}

@media screen and (min-width: 1200px) {
  .dash-responsive {
    /* max-width: 1400px;
    margin: 0 auto; */
    padding-left: 15px;
  }
  

}

.dashboard-p1{
  position: absolute;
 top: 17px;
 left: 20px;
 font-size: 16px;
 font-family: "Inter";
 font-weight: 500;
 color: #0E0E0E;
 font-style: normal;
}

.dashboard-p2{
  position: absolute;
 top: 47px;
 left: 20px;
 font-size: 24px;
 font-family: inter;
 font-weight: 500;
 font-style: normal
}

.overflowDashboardcard {
  overflow:hidden;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent; 
}
.overflowDashboardcard::-webkit-scrollbar {
  display: none; 
}

@media screen and (max-width:650px){
  .todayactivityfont{
    font-size: 15px !important;
    gap: 10px;
  }
}

.custom-input::placeholder {
  color: white;
}


.activeItemStyle::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: rgb(82, 161, 255);
  transition: width .5s ease-in;
}

.activeItemStyle:hover::after {
  width: 100%;
} 

.activeItemStyle2::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  margin-left: 12px;
  background: rgb(82, 161, 255);
  transition: width .5s ease-in;
}

.activeItemStyle2:hover::after {
  width: 85%;
} 


/* User OnBoarding */
/* .useronboardingstep1{
} */
.shepherd-element {
  max-width: 520px !important;
}
.userstep1text{
  text-align: center;
  color: rgba(38, 50, 56, 1);
  font-weight: 500;
  font-size: 15px;
  padding: 5px;

}
.shepherd-button.shepherd-button-secondary{
  background: white !important;
  color: black !important;
}
.shepherd-footer .shepherd-button:last-child{
  width: fit-content !important;
}
.highlighted-element{
  border: 3px  dotted red !important;

}
.no-pointer{
  pointer-events: none;
  cursor: none;
}

#highlightdetailsstep2{
  padding: 5px;
}
.homefeaturehighlight{
  text-align: center;
  color: rgba(69, 90, 100, 1);
  font-weight: 600;
}
/* .reservationdottedline{
  border: 1px solid red;
} */
.shepherd-button{
  width: max-content !important;
  background: rgb(0, 175, 0) !important;
  color: white;
}
.shepherd-button:hover{
 
}
.custom-close-button {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 18px !important;
  color: gray; /* Change the color as desired */
  margin-left: 10px; /* Adjust the spacing as needed */
  position: absolute;
  top: 1% !important;
  right: 2% !important;
  padding: 4px !important;
}
.custom-close-button:hover {
  color: gray !important; /* Change to gray on hover */
}
.highlighted-elements{
  padding: 5px;
  background: rgb(255, 255, 255) !important;
  z-index: 999 !important;
}
.useronboardingstep1 img{
  height: 180px !important;
}
.step2class{
  margin-top: 15px !important;
}
.step3class{
  margin-top: 15px !important;
}
.step4class{
  margin-left: 15px !important;
}
.step5class{
  margin-left: 15px !important;
}
.step6class{
  margin-left: -15px !important;
}

.step8class{
  margin-left: 15px !important;
}

@media screen and (max-width:1095px) {
  #highlightdetailsstep2{
    display: grid !important;
  }
}
/* @media screen and (min-width: 1025px) and (max-width: 1399px) {
  .dashboard-p2 {
    font-size: 17px !important;
  }
} */
@media screen and (max-width:1427px) {
  .cardheaderdashb {
    height: 110px !important;
  }
  
}
@media screen and (max-width:839px) {
  .w-200.mb-3  {
   width: 140px !important;
   font-size: 15px !important;
  
   
  }
  .cardheaderdashb{
    margin: 0.5rem !important;
    margin: auto !important;
    max-width: 90%;
  }
  
}
@media screen and (max-width:800px) {
 .activitypageresponsive {
 font-size: 0.8rem !important;
 text-align: center !important;
 }
 .activitypageresponsivetd>td{
  font-size: 0.7rem !important;

 } 
}



.popup-content{
  z-index: 0 !important;
}
.apexcharts-legend-series{
  margin-top: 3px !important;
}


.viewallbutton {
  display: inline-block;
  padding: .45rem 0.75rem;
  width: 120px;
  border-radius: 10px;
  color: #fff;
  text-transform: 'none';
  font-size: 1rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  font-family: 'Inter';
  font-weight: 600;
  z-index: 1;
  border: 2px solid;
}


.viewallbutton::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1976d2;
  border-radius: 10px ;
  z-index: -2;
  
}

.viewallbutton::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: white; /* darken(#0cf, 15%) */
  transition: all .3s;
  border-radius: 10px ;
  z-index: -1;
}

.viewallbutton:hover {
  color: #1976d2;
}

.viewallbutton:hover::before {
  width: 100%;
}