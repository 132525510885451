@media print {
    .print-only {
      display: block;
    }
  
    .no-print {
      display: none;
    }
   
  }
  
  @media screen {
    .print-only {
      display: none;
    }
  }