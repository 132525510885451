.card {
  background: transparent !important;
  border: 1px solid;
}

.hr {
  /* height: 3px; */
  color: #c0c0c0;
  padding-top: 4px;
}

.PR select option:hover {
  background-color: lightblue;
  color: black;
}

select option {
  background-color: white;
  
}

.maxAge{
  font-style: normal;
  margin-left: -30px;
  font-weight: 500;
  font-size: 14px;
  color: #5e5e5e;
}

.button {
  float:inline-end;
  width: 115px;
  text-align: center;
  height: 36px;
  background: transparent;
  border: 1px solid rgba(95, 95, 95, 0.3);
  border-radius: 5px;
  color: #252525 !important;
  font-style: normal;
  font-family: inter;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  line-height: 2.2em !important;
}

.pMute {
  font-size: 12px !important;
  width: 120px;
  color: #5f5f5f !important;
  line-height: 12.1px;
}

.occupancy {
  color: rgb(220 87 87) !important;
  font-size: 14px !important;
  font: 400 !important;
}

.side {
  background: transparent !important;
  border: 1px solid rgba(95, 95, 95, 0.5);
  color: #5f5f5f;
  font-size: large !important;
  height: 25px;
  line-height: 0.8px;
}

.side:hover {
  /* background: #2b60ec !important; */
  color: #1AB64F !important;
}

.input {
  height: 25px;
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(95, 95, 95, 0.5);
  box-shadow: inset 0px 4px 4px rgba(209, 202, 202, 0.25);
}

.bookingReserve {
  width: 160px;
}

.booking {
  float: right;
}

.booking,
.bookingReserve {
  margin-right: 20px;
  border: none;
  margin-bottom: 15px;
  background: #1ab64f !important;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
}

.booking:focus,
.bookingReserve:focus {
  background: #1ab64f !important;
}

.booking:hover,
.bookingReserve:hover {
  background: #128c3a !important;
}
.buttonsearchamenities{
  
  background: #1976CC !important;
  border:none !important
}
.label {
  font-weight: 400;
  font-size: 10px;
  /* line-height: 12px; */
  color: #5f5f5f;
  opacity: 0.7;
  display: flex;
  gap:5px;
  align-items: baseline;
}

.user {
  text-decoration: none !important;
  font-weight: 400;
  font-size: 16px;
  color: #5f5f5f;
  opacity: 0.7;
  margin-left: 20px;
  margin-top: 10px;
}

.add {
  width: 115px;
  max-width: 120px;
  height: 36px;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-style: normal;
  font-family: inter;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  /* margin-left: 20px; */
}

.icon {
  width: 30px;
  font-size: 15px;
  height: auto;
}

.card-footer {
  font-size: 10px;
  font-weight: 400;
  font-size: normal;
  font-family: inter;
  color: #000000;
  border: none;
  background: none;
  padding: 5px ;
  width: 110%;
  word-wrap: normal;
}

.card-text {
  font-size: 16px;
  color: #5f5f5f;
  font-weight: 400;
  font-family: inter;
  font-style: normal;
  opacity: 0.7;
}

.card-header {
  font-size: 24px;
  font-weight: 500;
  font-size: normal;
  font-family: inter;
  color: #252525;
  border: none;
  background: none;
  width: "105%";
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

.card-body{
  width:"105%";
  padding-left:0;
  margin-left: 0;
  height: 140px;
  
}

.search {
  width: auto;
  height: auto;
}

.disable {
  /* position: fixed;  */
  /* background-color: rgba(0, 0, 0, 0.3) !important; */
  /* cursor: not-allowed; */
  cursor: auto;
  /* pointer-events: none; */
}

.enable {
  opacity: 1;
  cursor: auto !important;
  pointer-events: all !important;
}

.warning {
  z-index: 1;
  /* position: absolute; */
  position: fixed;
  width: 60%;
  margin: 50px;
  /* top: 0px; */
}
.warningmsg{
  z-index: 1;
  width: 50% !important;

 
  /* top: 0px; */
}
.warningreservation{
  z-index: 1;
  position: fixed;
  width: 75%;
 
}
.card-start {
  background: none;
  font-weight: 500;
  font-family: inter;
  font-size: 20px;
  color: #252525;
  border: none;
}

.price {
  color: #ff0000;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  opacity: 0.7;
  font-family: inter;
  text-decoration: none;
  padding-left: 0;
  margin-left: 0;
  padding-top: 0;
  margin-top: 0;
  
}

.price:hover {
  color: #ff0000;
}

.filter {
  width: 20px;
  height: 18px;
  margin-left: 10px;
}

.accordion-head > button[aria-expanded="true"] {
  color: #3284f7 !important;
  font-weight: 500;
}

.phoneNum input[type="tel"] {
  width: 230px;
}

.Accordion-checkbox {
  width: 100px;
}

/* .parentCheck {
} */

.dropdown button {
  border: 1px solid #dbdbdb !important;
  border-radius: 5px;
  background: transparent !important;
  color: #3d3737 !important;
  font-weight: 400;
  font-size: 14px;
  width: 292px;
  text-align: left;
  height: 40px;
}

.dropdownB button {
  border: none;
  background: transparent !important;
  color: #3d3737 !important;
  font-weight: 400;
  font-size: 14px;
  width: 292px;
  text-align: left;
  height: 40px;
}

.dropdown1 button {
  border:1px solid lightgray !important;
  border-radius: 5px;
  background: rgba(255, 255, 255, 1) !important;
  color: #3d3737 !important;
  font-weight: 400;
  font-size: 14px;
  width: 292px;
  text-align: left;
  height: 40px;
}
.dropdown9 button {
  border:1px solid lightgray !important;
  border-radius: 5px;
  background: #47ADFF !important;
  color: #3d3737 !important;
  font-weight: 400;
  font-size: 14px;
  width: 292px;
  text-align: left;
  height: 40px;
}
.dropdown9 .btn-primary:focus{
  background: #47ADFF !important;
}


.dropdown button:hover,.dropdownB button:hover{
  background: transparent !important;
}

.dropdown button:focus {
  background: transparent !important;
}
.dropdown1 button:hover {
  background: rgba(255, 255, 255, 1) !important;
}
.dropdown1 button:focus {
  background: rgba(255, 255, 255, 1) !important;
}




.dropdown-menu,
.row-section option {
  width: 292px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  max-height: 190px;
  /* top: 50px !important; */
  /* bottom: 0px; */
  /* transition: auto 0s ease 0s !important; */
  /* transform: translate3d(0px, 6px, 0px) !important; */
  transform: auto;
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: thin; 
  background: #edf6ff !important;
}

.dropdownB-menu{
  font-size: 16px;
  margin-left: 10px;
  font-weight: 400;
  border: none;
  height: 170px;
  overflow: scroll;
  overflow-y: auto;   /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  text-align: left;
  /* top: 50px !important; */
  /* bottom: 0px; */
  /* transition: auto 0s ease 0s !important; */
  /* transform: translate3d(0px, 6px, 0px) !important; */
  transform: auto;
}


.dropdown-div {
  border-radius: 5px 5px 0px 0px;
}

.dropdown-div:hover,
.row-section option:hover {
  background: #47adff !important;
  color: #ffffff !important;
}

.reservation-start {
  background: white;
  height: 100%;
}

.reservation-start > .nav-tabs {
  border: none !important;
}

.modal-header {
  background: transparent !important;
  color: black;
}

.modal-body {
  height: 300px;
  font-size: 16px;
  font-weight: 500;
  max-height: 350px;
  overflow-y: scroll;
}

.row-model:hover {
  background: #f5fbff;
}

.modal-body input[type="text"],
.modal-body input[type="number"] {
  border: none;
  border-bottom: 1px solid gray;
  color: #5e5e5e !important;
  border-bottom-left-radius: 0px !important;
  width: 80px;
  background: transparent;
  float: right;
  border-bottom-right-radius: 0px !important;
  margin-bottom: 5px;
  border-bottom-style: dotted !important;
}

.modal-body::-webkit-scrollbar {
  margin-left: 12px;
}

.row-model {
  background: #f7f7f7;
}

.modal-footer {
  background: transparent !important;
  border: none !important;
}

.modal-footer button[type="button"] {
  width: 100% !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  background: #4e98f3 !important;
  border-color: transparent;
}

.modal-footer button[type="button"]:hover {
  background: #2781f0 !important;
}

/* .row-section {
  padding-bottom: 100px;
} */

.row-section input[type="date"],
.row-section select,
.row-section input[type="text"] {
  width: 295px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .row-section input[type="date"],
  .row-section select,
  .row-section input[type="text"],
  .dropdown button {
    width: 155px;
  }

  .row-section option {
    width: 155px !important;
  }
}

.pay-card-textA,
.pay-card-textB,
.pay-card-textC {
  font-weight: 500 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-family: Inter !important;
  color: #5f5f5f !important;
}

.pay-card-textA {
  color: #5f5f5f;
}

.pay-card-textB {
  color: #252525;
}

.pay-card-textB {
  color: #5f5f5f;
}

.pay-this,
.pay-not {
  float: right;
  font-family: inter;

  font-style: normal;
  color: #000000;
}

.pay-not {
  font-size: 16px !important;
}

.pay-this {
  text-decoration: line-through !important;
  font-size: 12px !important;
}

.popover-img {
  width: 40px !important;
  height: 35px !important;
  margin-top: 5px !important;
}

.popover-body {
  width: 250px;
}

.popover-row {
  margin-top: 5px;
  border-top: 1px solid #5e5e5e;
  border-right-width: 1px;
}

.popover-str {
  margin-top: 10px !important;
  font-style: normal;
  font-weight: 500;
}

.payment-card-text {
  font-weight: 500 !important;
  font-size: 24px !important;
  font-style: normal;
  font-family: "Inter";
  color: #252525;
  text-decoration: none !important;
  border: none !important;
  outline: none !important;
}

.payment-card-text:hover {
  color: #252525 !important;
}

.payment-card-text:focus {
  border: none !important;
  outline: none !important;
}

.payment-card-text button {
  margin-bottom: 5px;
}

.max-occupancy {
  margin-top: 0.6rem;
  margin-right: 13px;
  margin-left: -30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #5e5e5e;
}

.adult-msg {
  margin-top: -15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
}

.warn-msg {
  font-size: 10px;
  font-weight: 500;
}

.smlImg {
  width: 15px !important;
  height: 15px !important;
  border-radius: 0px !important;
}


.smlImg{
    margin-right: 6px;
    width: 15px !important;
    height: 15px !important;
    border-radius: 0px !important;
}

.headBtn{
    background: transparent;
    border: none !important;
}
.newamenitiesbutton{
  background: #1AB64F !important;
  border: none !important;
}
.headBtn button, .headBtn a{
    float: right;
    margin-top: 10px;
    background: #1AB64F !important;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    width: 163px;
    height: 40px;
}
.dth{
    margin-top: 10px;
}

.inFoot{
    margin-top: 20px;
    background: none !important;
    border: none;
}

.inRow button{
    border: none;
    background: transparent !important;
    color: #FFFFFF;
    font-weight: 500;
}

.inRow button:nth-child(2){
    color:black;
}

.inRow{
    height: 70px;
    border: 1px solid #47ADFF;
}

.inCol1{
    
    background: #47ADFF;
}

.inCol1 button, .inCol1 button:focus, .inCol1 button:hover{
    margin-top: 10px;
    color:white !important;
    background: #47ADFF !important;
}

.inCol2 button{
    margin-top: 10px;
    color: #5E5E5E;
}

.inCol2:hover, .inCol2:focus, .inCol2 button:active{
    background: #47ADFF !important;
    color: #5E5E5E;
}

.inCol2 button:hover, .inCol2 button:focus{
    background: transparent !important;
    color: #5E5E5E;
} 

.inImg img{
    margin-top: 5px;
    width: 60px;
    height: 50px;
    float: right;
}

.btnIn button{ 
    border: 1px solid #5E5E5E !important;
    margin-left: 10px;
    width: 133px;
    height: 40px
}

.inP{
    margin-top: 20px;
}

.btnIn button:hover{
    border: none !important;
    background:#47ADFF;
    color: #FFFFFF;
} 

.inP{
    margin-top: 10px;
}

.popB, .popB:hover,.popB:focus{
    background: none !important;
    border: none !important;
}

.extra{
    margin-top: -15px;
    width: 80px;
    border: none;
    border-bottom: 1px solid black;
    border-bottom-style: dashed;
}

.labelFront{
  background-color: #ccc; 
  pointer-events: none; /* Prevent clicks */
  position: relative;
}

.unavailableOverlay span{
    object-fit: cover;
    clip-path: polygon(50% 0%, 0% 0%, 0% 50%); /* Creates a triangle shape */
    background: red;
    position: absolute;
    height: 500px;
    rotate: 4;
    width: 500px;
}

.labelFront span p{
    margin-top: 165px;
    color: white;
    margin-left: 20px;
    font-size: 35px;
    transform: rotate(327deg);
    transform-origin: 0 0;
    transform: rotate(312deg);
}

.time p{
  font-size: 14px;
  font-weight: 600;
}

.unavailableOverlay {
  position: absolute;
  
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark transparent overlay */
  color: white;
  display: flex;
  justify-content: flex-start;
  
  z-index: 10; /* Ensures it is above everything */
  text-align: center;
  border-radius: 6px;
}

.timerText {
  font-size: 16px;
  font-weight: bold;
  /* background: rgba(255, 0, 0, 0.8);  */
  padding: 50px;
  margin: 80px;
  border-radius: 5px;
}

.unavailableOverlay span p{
  margin-top: 165px;
    color: white;
    margin-left: 20px;
    font-size: 30px;
    transform: rotate(327deg);
    transform-origin: 0 0;
    transform: rotate(313deg);
}

/* 
.timerS{
  font-size: 18px;
  color: white;
  position: absolute;
  margin-left: 140px;
 
  margin-top: 150px;
} */

.bookingenginerep{
  padding: 10px;
 
}
/* @media screen and (max-width:1038px) {
    .rowBookingEngine{
      display: flex;
      flex-direction: column;
    }
}
@media screen and (max-width:539px) {
 .booking{
  left:40% !important
 }
}

@media screen and (max-width:1038px) {
  .mobileSpacing{
    margin-left: 0px !important;
    width: 100%;
  }
  .bookingenginerep{
    width: 100%;
  }
} */
/* @media screen and (max-width:425px) {
  .cardnewname{
    display: flex !important;
    gap:10px;
    width: max-content;
    padding: 2px;
  }
  .card-header{
    text-align: center;
  }
  .occupancy{
    text-align: center;
    width: 100%;
  }
  .headerimagenone{
    display: none;
  }
} */

/* @media screen and (max-width:575px) {
  .cardnewname{
    display: flex;
    gap: 10px;
  }
  .headerimagenone{
    display: none;
  }
  .card-header{
    text-align: center;
  }
  .occupancy{
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
} */

/* @media screen and (min-width: 576px) and (max-width: 767px) {
  .cardnewname {
   gap: 10px;
   display: flex;
  }
  .occupancy{
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
  .card-header{
    text-align: center;
  }
} */
/* @media screen and (max-width:556px) {
  .bookingenginerep{
    padding: 0px;
  }
} */

/* @media screen and (max-width:384px) {
  .bookingenginerep{
    padding: 0px;
  }
  .newimagedimention{
    height: 80px !important;
    width: 80px !important;
  }
} */

td{
  position: static!important;
}

.vertical-tooltip .tooltip-inner {
  white-space: nowrap; /* Prevent tooltip content from wrapping */
}

.vertical-word {
  display: inline-block;
  writing-mode: vertical-rl; /* Vertical writing mode, right to left */
  text-orientation: mixed; /* Mixed text orientation for compatibility */
  margin-right: 5px; /* Adjust spacing between vertical words */
}

.bookingReserve{
    
  text-align: center;
  position: fixed !important;
  bottom: 0%;
  left: 50%;
  transform: translateY(-50%);
  z-index: 999;
}

.book-Button{
  text-align: center;
  position: fixed !important;
  bottom: 0%;
  left: 50%;
  transform: translateY(-50%);
  z-index: 999;
}

.responsiveRow{
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  margin-right: 50px;
}

@media (min-width: 300px) and (max-width: 990px) {
 
    .mainImage {
      display: flex;
      justify-content: center;
    }

    .cardnewname{
      display: flex !important;
      justify-content: center;
      justify-content: space-between;
    }

    .mobileSpacing{
      margin-left: 0px !important;
      padding: 50px;
    }
    .mobileSpacingProperty{
      /* margin-left: 0px !important; */
      padding: 35px;
    }

    .book-Button{
      text-align: center;
      position: fixed !important;
      top: 0%;
      left: 50%;
      transform: translateY(-50%);
      z-index: 999;
    }
    .bookingReserve{
    
      text-align: center;
      position: fixed !important;
      top: 0%;
      left: 50%;
      transform: translateY(-50%);
      z-index: 999;
    }
    
    .button{
      float: none !important;
    }
    .plusminus{
      /* margin-left: 55px; */
      width: 113px !important;
    }

    .responsiveRow {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }
    .colButton{
      padding-top:0 !important ;
    }

}

@media (min-width:991px) and (max-width: 1400px) {
  .mainImage {
    display: flex;
    justify-content: center;
  }
  .thumbnail{
    flex: 0 0 auto;
    width: 100%;
  }
  
  .bookingenginerep{
    display: flex;
    flex-direction: column;
  }
  
  .cardnewname{
    display: flex !important;
    justify-content: center;
    justify-content: space-between;
  }
 
  .twoRows {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .customCol {
    flex: 1 0 48% !important;
    max-width: 48% !important;
  }

  .responsiveRow {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
 
  .price{
    margin-top: 10px;
  }
  .button{
    margin-bottom: 3px;
  }
  .button{
    float: none !important;
  }
  .colButton{
    padding-top:0 !important ;
  }

  .row3{
   padding-top: 45px;
  }

 

  
}
.inputrowreservation{
  display: flex; /* Use flexbox for layout */
  flex-wrap: wrap;
  margin-bottom: 10px; 
  justify-content: space-between;
  gap:15px
}
.inputrowreservationbottomlayer{
  display: flex ;
  justify-content: space-between;
}
/* .button {
  margin-left: 70px;
  background-color: #007bff;
  color: black;
  cursor: pointer;
} */

.button:disabled {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}


@media screen and (max-width:839px) {
  .reservationinputswidth{
    width: 100px !important;
    height: 40px !important;
    
  }
}


@media (max-width: 430px) {
  .navbar-brand {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navbar-brand .input-group {
    width: 50%; /* Adjust to cover most of the screen width */
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px; /* Add space between input and button */
  }

  .navbar-brand .input-group .input-group-text,
  .navbar-brand .input-group .form-control {
    padding: 8px;
    margin: 0;
    width: auto;
  }

  .navbar-brand .input-group .form-control {
    flex-grow: 1; /* Make input grow to take remaining space */
    min-width: 0; /* Prevent overflow */
  }

  .navbar-brand .headerimagenone img {
    width: 50%; /* Let the search button span full width */
    padding: 5px;
  }

  .search-button {
    width: 50%; /* Make the search button larger */
    padding: 5px 0;
    font-size: 12px; /* Increase font size */
  }



  /* -------------   IMAGES   ---------------------------- */

  #image-part {
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center items */
  }

  .thumbnail {
    display: flex;
    justify-content: center; /* Center thumbnails */
    margin-top: 10px; /* Add some spacing */
  }


  .cardnewname {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 10px 0; /* Add some vertical padding */
    display: flex;
    justify-content: space-between;
  }

  .cardnewname img { /* Target images inside the thumbnail container */
    width: 100%; /* Reduce thumbnail size for mobile */
    height: 85px;
    margin-right: 10px; /* Add space between thumbnails */
    flex-shrink: 0; /* Prevent images from shrinking */
    
  }


  

  .mainImage img {
     width: 100%; /*Make the main image responsive */
    height: auto /* Maintain aspect ratio */
  }

  .card-header{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }


  /* ------------------------------------- */
  .adult-msg {
    margin-top: -15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    color: #ff0000;
  }
}


.hovereffect:hover {
  /* transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  transform: scale(0.9); /* Zoom out effect */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}



