.dt{
    font-size: 14px;
    font-weight: 600;
}

.head{
    padding: 20px;
    background: #47ADFF;
    color: white;
    font-size: 20px;
    font-weight: 500;
    border-radius: 12px;
}

.title{
    background: none !important;
    border: none !important;
}

.Title{
    font-size: 28px;
}
.subTitle{
    font-size: 16px;
    color: rgb(7, 71, 166);
}

.dropdownNav{
    font-size: 18px;
}
.dropdownNav2{
    font-size: 15px;
}

@media (min-width:767px) and (max-width:910px){
        .Title{
            font-size: 17px;
        }
        .subTitle{
            font-size: 12px;
        }
        .dropdownNav{
            font-size: 15px;
        }
        .dropdownNav2{
            font-size: 13px;
        }
        .dt{
            font-size: 10px;
        }
}

