.filter-panel {
    width: 25%;
    padding: 20px;
    background: white;
    border-right: 1px solid #ddd;
  }
  .custom-selectcoupontype{
    color: black !important;
  }

  #thead-couponhead{
    background-color:rgb(71, 173, 255) !important;
    color: white;
  }

  .tooltip {
    opacity: 1;
    background-color: black;
    color: white;
    padding: 5px;
    transform: translateY(-50%);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    border-radius: 3px;

  }
  .coupon-grid {
   
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: -15px;
  }
  
  .coupon-card {
    background: white;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    width: 200px;
    
    
  }
  .coupon-card h6{
    padding-top: 7px;
    font-size: 15px;
  }
  .coupon-card img{
    height: 110px;
    width: 100%;
  }
  .coupontime {
    padding: 5px; 
    background: #FFFAF5; 
    border: 1px solid #A7622A;
    border-radius: 5px; 
    display: flex; 
    align-items: center;
    justify-content: center; 
    margin-top: 10px; 
    width: fit-content;
  }
  
  .coupontimes {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between icon and text */
  }
  
  .coupontimes img {
    height: 15px; /* Increase size for better visibility */
    width: 15px;
    object-fit: contain;
   
  }
  
  .coupontimes p {
    font-size: 12px; 
    margin: 0; 
    color: #A7622A;

  }
  .copy-coupon-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    text-align: center;
  }
  
  .copy-coupon-btn {
    width: 100%;
    background-color: #47ADFF; /* Green background */
    color: white;
    border: none;
    border-radius: 5px 0 0 5px; /* Rounded corners for left side */
    padding: 5px 8px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-right: none; /* Remove border on the right to blend with the code box */
  }
  
  .copy-coupon-btn:hover {
    background-color: #45a049;
  }
  
  .copy-coupon-btn:active {
    background-color: #3e8e41;
  }
  
  .coupon-code {
   
    align-items: center;
    background-color: #FFFFFF;
    padding: 4px 8px;
    font-size: 10px;
    width: 100%;
    border: 2px dotted #47ADFF;
   
    border-radius: 0 5px 5px 0; /* Rounded corners for right side */
  }
  
  .last-digits {
    font-weight: bold;
    color: #333;
  }
  


  /* Coupon Form */

  .couponforms{
    padding: 20px;
    border: 1px solid #838997;
    border-radius: 12px;
    height: 100%;
    max-width: 70%;
    margin: auto;
  }
  .couponforms h4{
    font-size: 18px;
  }
  .couponselect{
    width: 100%;
    padding: 10px;
    border-radius: 17px;
    font-size: 16px;
    background: white !important;
    border: 1px solid #CCCDCE !important;
    text-decoration: none;
    outline: none;  
    color: black !important;   
    text-align: left !important;  

  }
  .couponselect:hover{
    background: white !important;
  }
  .couponselect1:hover{
    background: rgb(166, 212, 255) !important;
  }
  .edit-modcoupon{
    background: #D3D3D3 !important;
  }
  .edit-modcoupon:hover{
    background: #D3D3D3 !important;
  }
  .couponselect1{
    width: 100%;
    padding: 10px;
    border-radius: 17px;
    font-size: 16px;
    background: rgb(166, 212, 255) !important;
    border: 1px solid #CCCDCE !important;
    text-decoration: none;
    outline: none;  
    color: black !important;   
    text-align: left !important;  
  }
  .couponselects{
    width: 47%;
    border-radius: 17px;
    font-size: 12px;
    padding: 5px;
    outline: none;            

   
    text-decoration: none;
  }
  .couponlabel{
    display: block;
    font-size: 10px;
    padding-left: 5px;
  }
  @media screen and (max-width:970px) {
    .couponforms{
      max-width: 100%;
    }
  }


.filter-panel {
    padding: 30px; 
  }
  
  .filter-panel h3 {
    margin-bottom: 40px; 
    
  }
  
  .filter-panel input,
  .filter-panel select {
    border-radius: 20px;
    padding: 10px; 
    margin: 15px 0; 
    border: 1px solid #ccc;
    outline: none;
    width: 100%; 
    box-sizing: border-box; 
  }
  
  .filter-panel input:focus,
  .filter-panel select:focus {
    border-color: #007bff; 
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .filter-panel select {
    appearance: none; 
  }
  
  .filter-panel ul {
    margin-top: 25px;
    padding-left: 20px; 
  }
  


  
  .dotted-line {
    border: none; 
    border-top: 2px dotted #ccc;
    margin: 30px 0; 
  }

  .custom-select {
    position: relative;
  }
  
  .custom-select::after {
    content: '▼';
    font-size: 12px;
    color: #333; 
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
  }


  .item-list {
    list-style: none; 
    padding: 0;
  }
  
  .item-list li {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    margin: 10px 0; 
  }
  
  .item-list label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .item-list input[type="radio"] {
    margin-right: 10px; 
  }
  
  .item-count {
    color: #555; 
    font-size: 0.9rem; 
  }




  .category-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .category-name {
    font-size: 14px;
    color: #3d3737;
  }
  
  .category-count {
    font-size: 14px;
    font-weight: bold;
    color: #999;
  }
  
  .error-text{
    color: red;
    font-size: 12px;
    padding-left: 8px;
  }







  #table-container {
    width: 100%;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  #table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9;
  }
  
  #head {
    background-color: #eaf5fb;
  }
  
 #td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  #th {
    font-weight: bold;
    text-align: center;
    padding: 10px;
  }
  
  .edit-btn {
    background-color: #4c99f8;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .delete-btn {
    background-color: #fff;
    color: #f03a3a;
    border: 1px solid #f03a3a;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-btn:hover {
    background-color: #3b82f6;
  }
  
  .delete-btn:hover {
    color: #fff;
    background-color: #f03a3a;
  }
  

  .coupon-code {
    display: flex;
    align-items: center;
    gap: 5px; /* Adds spacing between the icon and the code */
  }
  
  .copy-icon {
    
    cursor: pointer;
    color: #007bff; /* Icon color */
    font-size: 18px; /* Icon size */
  }
  
  .copy-icon:hover {
    color: #0056b3; /* Hover effect */
  }
  