.dot {
    height: 35px;
    width: 35px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  .userinfodetailscrolls{
    overflow: hidden !important;
    scrollbar-width: none;
  }
  .p1{margin-top:4px;
  margin-left: 12px;
  color: white;}
  .bt{
    background:  #0051ff;
    color:white;
  }
  .check{margin:5px;
    }
  .h5{
    height: 50px;
    width: 1500px;
    background: linear-gradient(90deg, #24a0ed 0%, #0051ff 51%, #24a0ed 100%) !important;   
  }
  .roomModal  .modal-header{
    background: rgb(71, 173, 255) !important;
    border-radius: 10px 10px 0px 0px;
  }
  .facModal  .modal-header{
    background: rgb(71, 173, 255) !important;
    border-radius: 10px 10px 0px 0px;
  }
  .modalRm .modal-header{
    background: rgb(71, 173, 255) !important;
    border-radius: 10px 10px 0px 0px;
  }

  .otpverificationmodel .modal-content{
    width: 500px !important;
  }

  .extend-formal{
   
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #5F5F5F;
      font-weight: 500;
      font-size: 16px;
   
  }

  .ext-table table{
    padding: 0px !important;
  }

  .extend-formal-1{
   
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #5F5F5F;
    font-weight: 500;
    font-size: 14px;
 
}

  .h6{
    color: white;
    font-size: larger;
    margin-top: 8px;
  }
  .bg{
    background-color: #F9F6EE;
  }
  
  .pd{
    width: 1520px;
  }
 .ba{
width: 1520px;}
.ml{
  width: 1500px;
}
.title{
  font-weight: 600;
  font-size: x-large;
  font-family: 'Inter';
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
}
.d{
  margin-top: 6px;
 
}

select,option{
  background-color: #edf6ff;
  color: black ;
}

.input,.input-1,.brd,.form_in{
  border: none !important;
  border-bottom: 1px solid blue !important;
  background: none !important;
  
}
.form_in{
    width: 300px !important;
    
}
.form_in:focus{
    outline: none !important;

}

  
.m{
  margin-left: 300px !important;
}
.input-1{width: 80px;}
.input:focus { outline: none; }

.input-1:focus { outline: none; }
.date,.day{
  font-size: large;
  font-weight: 400;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  font-family: 'Inter';
}
.day{
  margin-left: 5px;
}


.w{
  padding-top: 20px !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.total_charges{
  visibility: hidden;
}
.img_reserve{
  width: 200px;
  height: 170px;
  border: 10px solid steelblue;
}
.wd_reserve{
  width: 1500px !important;
}
.img_1_reserve{
  width: 25px;
  /* margin-top: 5px; */
  margin-left: 20px;
}
.wid{
  width: 200% !important;
}
.leb{
  position: absolute;
  margin-left: 10px;
  font-weight: 500;
  margin-top: 5px !important;
}
.upload{
  margin-left: 15px;
  background-color: #0000A3;
  width: 200px;
  height: 40px;
  color: white;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.bil{
  background-color:whitesmoke;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  font-family:'Inter';
  font-weight: 600;
  font-size: medium;
}
.btn-group button {
  width:400px;
    background-color: #0000FF;/* Green background */
    border: 1px #0000FF; /* Green border */
    color: white; /* White text */
    font-size: large;
    font-weight: 600;
    font-family:'Inter';
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
  }
  .btn-a1{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  }
  .btn-b{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  }
  /* Clear floats (clearfix hack) */
  .btn-group:after {
    content: "";
    clear: both;
    display: table;
  }
  
  .btn-group button:not(:last-child) {
    border-right: none; /* Prevent double borders */
  }
  
  /* Add a background color on hover */
  .btn-group button:hover {
    background-color: #0000A0;
    
  }
  .ma-5{
      margin-left: 30px;
  }
  .option-b{
    width: 500px !important;
      /* display: none; */
      margin-left: 450px !important;
      font-size: larger;
      font-family:'Inter';
  }
  .btn-blue{
      background-color: #0000FF !important;
      color: white !important;
      border-radius: 25px !important;
      width: 250px;
      margin-left: 10px;
      -webkit-border-radius: 25px !important;
      -moz-border-radius: 25px !important;
      -ms-border-radius: 25px !important;
      -o-border-radius: 25px !important;
}
  .btn-blue:hover{
      color: white !important;
      background-color: #0000A0;
  }
  .option-c{
      /* display: none; */
      margin-left: 450px !important;
      width: 500px !important;
      font-size: larger !important;
      font-family: 'Inter' !important;
  }
  .guest-leb{
    font-size: larger;
  }
  

/* --------------summary ------------- */


.section {
  padding: 100px 0;
  position: relative;
}
.gray-bg {
  background-color: #f5f5f5;
}
img {
  max-width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}
/* About Me 
---------------------*/
.about-text h3 {
font-size: 45px;
font-weight: 700;
margin: 0 0 6px;
}
@media (max-width: 767px) {
.about-text h3 {
  font-size: 35px;
}
}
.about-text h6 {
font-weight: 600;
margin-bottom: 15px;
}
@media (max-width: 767px) {
.about-text h6 {
  font-size: 18px;
}
}
.about-text p {
font-size: 18px;
max-width: 450px;
}
.about-text p mark {
font-weight: 600;
color: #20247b;
}

.about-list {
padding-top: 10px;
}
.about-list .media {
padding: 5px 0;
}
.about-list label {
color: #20247b;
font-weight: 600;
margin-right: 10px;
position: relative;
}
.about-list label:after {
content: "";
position: absolute;
top: 0;
bottom: 0;
right: 11px;
width: 1px;
height: 12px;
background: #20247b;
-moz-transform: rotate(15deg);
-o-transform: rotate(15deg);
-ms-transform: rotate(15deg);
-webkit-transform: rotate(15deg);
transform: rotate(15deg);
margin: auto;
opacity: 0.5;
}
.about-list p {
margin: 0;
font-size: 15px;
}

@media (max-width: 991px) {
.about-avatar {
  margin-top: 30px;
}
}

.about-section .counter {
padding: 22px 20px;
background: #ffffff;
border-radius: 10px;
box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
}
.about-section .counter .count-data {
margin-top: 10px;
margin-bottom: 10px;
}
.about-section .counter .count {
font-weight: 700;
color: #20247b;
margin: 0 0 5px;
}
.about-section .counter p {
font-weight: 600;
margin: 0;
}
mark {
  background-image: linear-gradient(rgba(252, 83, 86, 0.6), rgba(252, 83, 86, 0.6));
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}
.theme-color {
  color: #fc5356;
}
.dark-color {
  color: #20247b;
}

.required:after {
  content:" *";
  color: red;
}
.roomhousemodal .modal-content{
  width:600px !important
}
.roomModal .modal-content{
  width: 700px;
  padding: 0px;
 
  border-radius: 10px 10px 0px 0px; 
}

.roomModal .btn-close::after{
  content: 'X';
  color:#ffffff;
  font-weight: 600;
}
.facModal .modal-content{
  width: 600px;
  padding: 0px;
  border-radius: 10px 10px 0px 0px; 
}

.facModal .btn-close::after{
  content: 'X';
  color:#ffffff;
  font-weight: 600;
}
#facilitymodaldata  .modal-content{
  width: 600px !important;
}
.modalRm .modal-content{
  width: 1000px !important;
  
  padding: 0px;
  border-radius: 10px 10px 0px 0px;
}

.modalRm .btn-close::after{
  content: 'X';
  font-weight: 500;
  color: #f5f5f5;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr)); /* Adjust 100px as needed */
  grid-gap: 20px; /* Adjust spacing between checkboxes */
}

.checkbox-grid-rm{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr)); /* Adjust 100px as needed */
  grid-gap: 20px; /* Adjust spacing between checkboxes */
}

.checkbox-grid-rm .form-check-input[type=checkbox]{
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);;
}


.checkbox-grid label {
  display: block;
}

.checkbox-grid .form-check-input[type=checkbox]{
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);;
}

.accordion-head > button[aria-expanded="true"] {
  color: #3284f7 !important;
  font-weight: 500;
}

.selectNT .modal-content{
  padding: 0px !important;
}

.selectNT img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.selectNT p{
  margin-top: 12px;
  font-family: 'Inter';
font-size: 16px;
font-weight: 500;
line-height: 24.2px;
text-align: center;
}

.selectNT button{
  color: #917821;
}

.selectNT button:hover{
  background: #FFCD1A;
 
}


.av label .onlystatuscolor{
  color:green;
}

.um label .onlystatuscolor{
  color: #FF0000;
}



.oc label .onlystatuscolor{
  color: #800080;
}
.clearbuttoncss{
  background: none !important;
  color: black !important;
  border: none !important;
}
.clearbuttoncss:hover{
  background: white !important;
}
.gapinroomstatus{
  display: flex;
  gap:3px !important
}


.minus-disable:disabled{
  color: #5f5f5f !important;
}
@media screen  and (max-width:1225px){
  .roomModal .modal-content{
    width: 100% !important;
    margin-left: 10px;
  }
  .modalRm .modal-content{
    width: 650px !important;
    
  }
}

.depositmodel .modal-content{
  height: 550px !important;
 
 
  
}
.depositmodel .modal-dialog{
  width: 100% ;

}

.savedcards{
  overflow:auto !important;
  
}
.nosavedcards{
  height: 0px !important;
}
.alertclassname .modal-content{
  width: 400px !important;
  height: 100% !important;
}
.newmodalroommove{
  width: 400px !important;
}