@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

body{
    font-family: Inter, sans-serif;
}

.modal-backdrop.show{
    opacity: 0.3;
}
.fade .model-extend .modal .show {
    z-index: 999 !important;
  }
  .modal  {
    z-index: 999 !important;
  }
.modal-backdrop {
    z-index: -1;
  }
  .go685806154{
    display: none !important;
  }
