.success{
    background: #D9FFDF !important;
    color:#1AB64F;
    width: 400px !important;
}
.notice{
    background: rgba(255, 246, 214, 1) !important;
    color: rgba(176, 142, 22, 1);
}
.success button{
    background: #1AB64F !important;
    color:white;
    border-radius: 5px;
}
.notice button{
    background: rgba(255, 205, 26, 1) !important;
    color:white;
    border-radius: 5px;
}

.danger{
    background: #FFE3E3 !important;
    color:#D32F2F;
    width: 400px !important;
}
.danger button{
    background: #D32F2F !important;
    color:white;
    border-radius: 5px;
}

.danger img, .success img{
    width: 30%;
}

