.sidebar-container #sidebar-wrapper {
    transition: width 0.3s ease; /* Add transition effect */
     /* Initial width when expanded */
     width: 240px;
  }
  
  .sidebar-container.collapsed #sidebar-wrapper {
    width: 70px !important; /* Width when collapsed */
  }


  @media screen and (max-width:1101px) {
    .sidebar-container{
      height: 100vh;
    }
  }
  /* @media screen and (max-width:1000px) {
    .searchSidebar{
      margin-top: 30%;
    }
  }
  @media screen and (min-width:1000px) {
    .searchSidebar{
      margin-top: 30%;
    }
  } */
  

  .sidebarsearchdetails{
    border:1px solid white !important
  }

  .sidebarsearchdetails:hover{
    border:1px solid white !important;
  }
  .sidebarsearchdetails::placeholder {
    color: white !important;
    opacity: 1; /* Ensures the placeholder is fully visible */
  }

  
 
  /* .hover-effectonimage:hover {
    filter: blur(2px); 
} */

