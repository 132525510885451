.bullet{
    font-size: 14px;
    width: px;
    width: 20px;
    height: 10px;
    margin-top: 5px;
    padding-right: 12px;
    margin-left: 8px;

}

.marked{
    margin-left: 25px;
    width: 80%;
    border-radius: 5px;
}

.logo{
     width: 50px;
     height: 50px;
     margin-left: 80;
}
.SidebarFont{
    font-family: Vipnagorgialla !important;
    font-size: 18px;
    margin: 0;
}
.SidebarFontBookingEngine{
    font-family: Vipnagorgialla !important;
    font-size: 25px;
    margin: 0;
    color: #2B60EC !important;
}