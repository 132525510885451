.checkincheckoutpaymentbutton {

  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  row-gap: 18px !important;
  padding-bottom: 20px;

}

.roomavailabledata {
  display: flex;
  gap: 20px;
  align-items: center;

}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.placeholderaddition::placeholder {
  font-size: 13px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.customeinputaudit::placeholder {
  color: red;
}

.verticalmiddledata {
  text-align: center !important;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.myinput:checked+.slider {
  background-color: #2196F3;
}

.myinput:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.myinput:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.fee3 {
  color: blueviolet;
}

.ouput {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}

.thumbnail {
  height: 200px;
}

.close {
  /* Position */
  position: absolute;
  /* z-index: 9999; */
  font-size: 30px;
  font-weight: 400;
  transition: 0.3s;
  color: white;
  padding-left: 230px;
  padding-right: 7px;
  margin-left: -5px;
}

.close:hover,
.close:focus {
  text-decoration: none;
  cursor: pointer;
  animation-name: img;
  animation-duration: 4s;
}

@keyframes img {
  from {
    color: yellow;
    font-size: 30px;
    font-weight: 400
  }

  to {
    color: red;
    font-size: 30px;
    font-weight: 600
  }
}

.upload {
  /* margin-left: px; */
  background-color: #0747A6;
  width: 130px;
  height: 40px;
  color: white;
  text-align: center;
  line-height: 35px;
  border-radius: 5px;
  cursor: pointer;
  /* border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
-webkit-border-top-left-radius: 20px;
-moz-border-top-left-radius: 20px;
-ms-border-top-left-radius: 20px;
-o-border-top-left-radius : 20px; */
}

.upload:hover {
  background-color: #00008B;
}

.delete {
  margin-left: px;
  background: transparent;
  /* background-color: rgb(255,0,0); */
  border-radius: 5px;
  border: 2px important;
  width: 110%;
  height: 40px;
  color: #D32F2F;
  /* border-top-right-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
  border: 1px solid #D32F2F;
}

.leb {
  margin-top: 20px;
  font-weight: 500;
  font-size: 1.3rem;

  /* position: absolute;
margin-left: 40px;
font-weight: 500;
margin-top: 5px !important; */
}

.img_reserve {
  width: 400px;
  height: 270px;
  border: 3px solid steelblue;
}

.facility {
  scrollbar-width: auto;
  height: 300px;
  overflow-y: scroll;
}

.add_more_f {
  border: none;
  color: white;
  background-color: #0747A6;
}

.add_more_f:hover {
  animation-name: add_more_f;
  animation-duration: 4s;
  background-color: rgb(1, 1, 122);
}

@keyframes add_more_f {
  from {
    background-color: #0747A6;
  }

  to {
    background-color: rgb(1, 1, 122);
  }
}

.confirm_f {
  border: none;
  background-color: #C3D7F5;
  color: #0747A6;
}

.confirm_f:hover {
  animation-name: confirm_f;
  animation-duration: 4s;
  background-color: #C3D7F5;
}

@keyframes confirm_f {
  from {
    background-color: #C3D7F5;
  }

  to {
    background-color: #9cbced;
  }
}

.delete_f {
  background-color: transparent;
  color: #0747A6;
  border: 1px solid #C3D7F5;
}

.delete_f:hover {
  animation-name: delete_f;
  animation-duration: 4s;
  background-color: transparent;
}

@keyframes delete_f {
  from {
    background-color: transparent;
    color: #0747A6;
  }

  to {
    background-color: #0747A6;
    color: #C3D7F5;
  }
}

.confirm_f,
.add_more_f,
.delete_f {
  /* margin-top: 30px; */
  border-radius: 5px;
  width: 100%;
  height: 30px;
}


.submit {
  margin-bottom: '20px';

  background: #1AB64F !important;
  border-radius: 5px;
  width: 180px;
  height: 40px;
}

.facility_upload {
  margin-top: 70px;
  border: 2px solid #1AB64F;
  width: 204px;
  height: 40px;
  background: #1AB64F;
  color: #FFFFFF;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
}


@keyframes facility_upload {
  from {
    background-color: #1AB64F;
    color: #006d25
  }

  to {
    background-color: #006d25;
    color: #1AB64F;
  }
}

/* Position the image container (needed to position the left and right arrows) */
.container {
  position: relative;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: #e4e4e4;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Container for image text */
.caption_container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Six columns side by side */
.column {
  width: 17.66%;
  display: inline-block;
  margin: 0;
}

/* Add a transparency effect for thumnbail images */
.demo {
  opacity: 0.6;
  height: 75px;
}

.active,
.demo:hover {
  opacity: 1;
}

.check_img {
  width: 25px;
}

.row {
  margin-right: 12.5px;
}

.i {
  width: 25px;
  margin-top: -5px;
}

.label {
  font-size: 14px;
  line-height: 19.36px;
  color: #252525;
}


.label input[type="checkbox"] {
  /* border-color: #47ADFF !important; */
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23); */
  border-radius: .25em;
}

.labelD {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #5E5E5E;

}

.labelD input[type="checkbox"] {
  border-color: #5E5E5E !important;
}


.label input[type="checkbox"]:checked {
  background-color: #47ADFF !important;
}

.model_content {
  padding: 0px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.del_con,
.del_fac {
  width: 18%;
  font-weight: 500;
  border-radius: 5px;
}

.del_fac {
  background: #47ADFF;
  color: white;
}

.del_con {
  border: 1px solid #47ADFF;
  color: #47ADFF;
}

.white_container {
  background: #FFFFFF !important;
}

.white_container img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.white_container p {
  margin-left: 10px;
  margin-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
}

.white_container h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px
}

.white_container hr {
  border-top: 1px dashed !important;
  background: none !important;

}

.white_container h4 {
  margin-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  /* identical to box height, or 90% */

  display: flex;
  align-items: center;
  float: right;
}

@media only screen and (max-width: 768px) {
  .white_container h4 {
    font-size: 12px;
  }
}



.info {
  border: 1px solid rgba(169, 166, 166, 0.5);
  padding: 0.5rem;
}

.subBtn {
  float: right;
  margin-top: 50px;
}

.subBtn button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  width: 162px;
  height: 40px;
}

.subBtn button:nth-child(1) {
  color: #5E5E5E !important;
  text-decoration: none !important;
}

.subBtn button:nth-child(2) {
  background: #47ADFF !important;
  border-radius: 5px !important;
  border-color: #47ADFF !important;
}

.subBtn button:nth-child(2):hover {
  background: #1395ff !important;

}

.subBtn button:nth-child(2):focus {
  background: #47ADFF !important;
  border-color: #47ADFF !important;

}

.Accordion>button[aria-expanded="true"] {
  color: #FFFFFF !important;
  background: #47ADFF !important;
  border: 0.5px solid #47ADFF !important;
  font-weight: 500;
}

.Accordion {
  border: 1px solid #47ADFF;
}

.parentCheck input[type="checkbox"] {
  background-color: #47ADFF;
  border: none;

}

.parentCheck {
  margin-left: 10px;
  margin-top: 10px;
}

.childCheck {
  margin-left: 10px;
  margin-top: 10px;
}

/* .parentCheck input[type="checkbox"]:checked {
background: #47ADFF !important;
border: none;
color:white
} */

/* .parentCheck input[type="checkbox"] {
appearance: none;
-webkit-appearance: none;
background-color: transparent;
border: none;
}

.parentCheck input[type="checkbox"]::before {
content: "";
display: inline-block;
width: 14px;
height: 14px;
border: 1px solid #47ADFF;
background-color: #ffffff;
margin-right: 5px;
}

.parentCheck input[type="checkbox"]:checked::before {
background-color: #47ADFF;
} */


.childCheck input[type="checkbox"] {
  border: 1px solid #47ADFF !important;
}

.childCheck input[type="checkbox"]:checked {
  border: none !important;
  background-color: #7df23d !important;

}

.i {
  width: 20px !important;
  height: 20px !important;
}

.i[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -25px;
  left: 0;
  padding: 4px;
  background-color: yellow;
  /* Change the background color */
  color: blue;
  /* Change the text color */
}

.ovBtn,
.ovBtn:hover,
.ovBtn:focus {
  background: none !important;
  border: none !important;
}

.divOv {
  background: #E1F1FF;

  width: 206px;
}

.divOv p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 9px;
  /* or 112% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #0747A6;
}

.model-content {
  padding: 10px !important;
  width: 130% !important;
}


.model-head {
  background: #47ADFF;
}

.model-head h5 {
  font-weight: 500;
  font-size: 16px;
}

.AddBtn {
  margin-bottom: 10px;
}

.AddBtn a {
  float: right;
  margin: 5px;
}

.noFacilities {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

}

@media screen and (max-width:1019px) {
  .responsiveSliderBar {
    height: 500px !important;
  }
}

@media screen and (max-width:550px) {
  .responsiveSliderBar {
    height: 300px !important;
  }
}

.confirmred {
  background: green !important;
  color: white !important;
}
.disabled{
  background: gray !important;
  color: white !important;
}

.confirmgreen {

  color: red !important;
}

.roomtableimage {
  display: flex;
  align-items: center;
  gap: 20px;
}

.roomtableimagerow {
  width: 170px;
  border-radius: 10px;
  height: 105px;
}

.roomtableimagerow img {
  width: 100%;
  border-radius: 10px;
  height: 100%;



}

.roomtablep {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

}

.roomtablep p {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  width: 100px !important;
}

.viewbuttoncolor {
  background: rgba(26, 182, 79, 1) !important;
  border: none !important;
}

.viewbuttoncolor:hover {
  background: rgba(26, 182, 79, 1) !important;
  border: none !important;
}

.searchcontainerroom {
  position: relative;
  width: 260px;
  /* Set the width according to your design */
  margin: 20px;
}

.roomstyle {
  /* Example styles, adjust as needed */
  display: flex;
  flex-direction: column;
}

.roomstyle .searchcontainerroom {
  /* Example styles, adjust as needed */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.roomstyle .searchinputroom {
  /* Example styles, adjust as needed */
  width: 100%;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.roomstyle .searchiconroom {
  /* Example styles, adjust as needed */
  margin-left: -30px;
  /* Adjust as needed */
}

.roomstyle .dropdown1 {
  /* Example styles, adjust as needed */
  width: 100%;
}

.roomstyle .dropdownArrow {
  /* Example styles, adjust as needed */
  margin-left: 10px;
}



.searchinputroom {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  border: none;
  height: 45px;

}

.searchiconroom {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #555;
  cursor: pointer;
  color: rgba(7, 71, 166, 1);
}

.dropdown1 button {
  border: 1px solid rgba(255, 255, 255, 1) !important;
  border-radius: 5px;
  background: rgba(255, 255, 255, 1) !important;
  color: #3d3737 !important;
  font-weight: 400;
  font-size: 14px;
  width: 292px;
  text-align: left;
  height: 40px;
}

.dropdown1 button:hover {
  background: rgba(255, 255, 255, 1) !important;
}

.dropdown1 button:focus {
  background: rgba(255, 255, 255, 1) !important;
}

@media screen and (max-width:1138px) {
  .roomtableimage {
    flex-direction: column;
  }

  .roomtableimagerow {
    height: 95px;
    width: 160px;
  }
}

@media screen and (max-width:999px) {
  .facilitiestable {
    flex-direction: column;
  }

}

@media screen and (max-width:1359px) {
  .notesresponsvie {
    width: 100% !important;
  }

  .notesreportsresponsvie {
    max-width: 100% !important;
  }
}

@media screen and (max-width:1085px) {
  .notesresponsvie {
    width: 100% !important;
  }

  .notesreportsresponsvie {
    max-width: 100% !important;
  }
}

@media screen and (max-width:1005px) {
  .containernotes {
    flex-direction: column !important;
    width: 100% !important;
  }

}

.suggestBtn {
  border: 1px solid #FFD5A1B2;
  background: transparent;
  color: #7A4C12;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  transition: background 0.5s ease, color 0.5s ease;
  text-align: left;

}

.suggestBtn:hover{
  background: #FFD5A180;
  color: #7A4C12;
}

.suggestSpan {
  border: 1px solid #5E5E5E;
  background: transparent;
  color: #111010;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;


}

.finalL {
  margin: auto;
  width: 50%;
  padding: 10px;
  font-family: Inter;
  font-size: 16px;
  font-style: italic;
  color: #5E5E5E;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  border-bottom: 1px solid #5E5E5E;

}

.selectCatLeb{
  font-family: Inter;
font-size: 18px;
font-weight: 300;
line-height: 24.2px;
text-align: left;
/* color: #5E5E5E; */
margin: auto;
padding:10px;
justify-content: center;
display: flex;
}
.subCat{
  font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 19.36px;
text-align: left;
width: 92px;
height: 32px;
top: 893px;
left: 893px;
gap: 0px;
border-radius: 5px 0px 0px 0px;
border: 1px 0px 0px 0px;
/* opacity: 0px; */
border-radius: 5px;
text-align: center;
border: 1px solid #0747A6;
color: #0747A6;
transition: background 0.3s ease, color 0.3s ease;
background: transparent;
}

.subCat:hover{
  background: #0747A6;
  color: white;
}
.selectCat input[type=checkbox]:focus{
  border-color: #5E5E5E;
}
.selectFac input[type=checkbox]:checked{
  background: #5E5E5E;
  border: none;
}
.selectFac input[type=checkbox]:checked::after{
  content: '✓';
  color: white;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: -3px;
}

.selectFac label{
  font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;
color: #5E5E5E;

}

.selectCat {
  margin: auto;
  width: 50%;
  padding: 5px;
  background: #47ADFF33;
  gap: 0px;
  border-radius: 5px 0px 0px 0px;
 
  font-family: Inter;
  font-size: 18px;
  font-weight: 300;
  line-height: 24.2px;
  text-align: left;
  color: #5E5E5E;
 
}

.selectCat option{
  background: #47ADFF33 !important;
  color: #5E5E5E !important;
  font-size: 16px;
}
.container-box {
  width: 100%;
  height: 50px; /* Adjusted height to fit inside button */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 40px; /* Adjusted size to fit inside button */
  position: absolute;
  bottom: 5px;
  left: 0;
  animation: fly 2s linear infinite; /* Faster animation for button */
}

@keyframes fly {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-15px) translateX(15px);
    opacity: 1;
  }
  50% {
    transform: translateY(0) translateX(30px);
    opacity: 1;
  }
  75% {
    transform: translateY(15px) translateX(45px);
  }
  100% {
    opacity: 0; /* Hides the image at the end */
    visibility: hidden;
  }
}